<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Voucher No</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Attachments</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in journals" :key="i">
                        <td>
                            <span class="fw-bold">{{ item.date }}</span>
                        </td>
                        <td>{{ item.voucher_no }}</td>
                        <td> {{item.note}} </td>
                        <td> {{ commaFormat(item.total_amount) }} </td>
                        <td>
                            <button v-if="item.attachments.length > 0" class="btn btn-primary btn-sm"
                                    style="cursor: pointer"
                                    @click="viewAttachments(item.attachments)"
                            >
                                <i class="fas fa-eye"></i>
                                View
                            </button>
                            <span v-else class="badge btn-danger">
                                No attachments
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-primary btn-sm"
                                    style="cursor: pointer"
                                    @click="goToEdit(item.id)"
                            >
                                <i class="fas fa-eye"></i>
                            </button>
                          <button class="btn btn-secondary btn-sm"
                                  @click="goToPrint(item.id)"
                                  style="cursor: pointer; margin-left: 10px"
                          >
                            <i class="fas fa-print"></i>
                          </button>
                        </td>
                    </tr>

                </tbody>
            </table>
            <p v-if="!journals.length" class="text-center mt-5">No data</p>
        </div>

        <ViewAttachment
            ref="viewAttachmentRef"
            :attachments="modalAttachments"
        />
    </div>
</template>

<script setup>
import {useStore} from 'vuex'
import figureFormatter       from '@/services/utils/figureFormatter'
import {computed, ref, onMounted}       from "vue";
import {useRoute, useRouter} from "vue-router";
import ViewAttachment from "@/components/molecule/ngo/journal/ViewAttachmentModal"
import ngoPdfPrinter from '@/services/utils/ngoPdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import handleReceipt from "@/services/modules/receipt";

const companyInfo = ref({});
const receipt = ref({});
const barcodeQuery = ref('');
const barcode = ref('');
const qrcode = ref('');
const {commaFormat} = figureFormatter ()
const $store = useStore()
const $router = useRouter()
const $route = useRoute()
const modalAttachments = ref([]);
const viewAttachmentRef = ref();
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF } = ngoPdfPrinter();
const { fetchBarcode } = handleBarcode();
const { fetchSingleReceipt } = handleReceipt();

onMounted(() => {
  fetchCompanyInfo($route.params.companyId).then( (res) => {
    companyInfo.value = res.data;
  }).catch( (err) => {
    console.log(err);
  })
})

const journals = computed( () => {
  return $store.getters["journal/getJournals"]
})

const goToPrint = (id) => {
  const companyQuery = `?company_id=${$route.params.companyId}`;
  fetchSingleReceipt(id, companyQuery).then( (res) => {
    if(res){
      receipt.value = res.data;
      let qrcodeData = JSON.stringify({
        ref_no: receipt.value.voucher_no,
        ref_date: receipt.value.date,
        created_by: receipt.value.user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery.value = `?code=${receipt.value.voucher_no}&qrcode=${qrcodeData}`;
    }}).then (() => {
    fetchBarcode(barcodeQuery.value).then( (res) => {
      barcode.value = res.barcode;
      qrcode.value = res.qrcode;
    }).then( () => {
      exportToPDF(companyInfo.value, receipt.value, barcode.value, qrcode.value, 'receipt');
    })}).catch((err) => {
      console.log('Something went wrong');
  })
}

const formatDate = (date) => {
  var formattedDate = date.toISOString().split('T')[0];
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
  return formatToDdMmYy(formattedDate) + ' ' + formattedTime
}

const formatToDdMmYy = (date) => {
  var dat = date.split('-');
  return `${dat[2]}-${dat[1]}-${dat[0]}`;
}

const goToEdit = (id) => {
  let params = {
    companyId: $route.params.companyId,
    moduleId: $route.params.moduleId,
    menuId: $route.params.menuId,
    pageId: $route.params.pageId,
    receiptId: id
  }
  let query = {
    start: $route.query.start,
    end:  $route.query.end
  }
  $router.push({name: 'receipt-voucher-np-edit',
    params: params,
    query: query
  })
}

const viewAttachments = (attachments) => {
    modalAttachments.value = [];
    modalAttachments.value = attachments;
    viewAttachmentRef?.value.toggleModal();
}
</script>
